.jumbotron {
  padding-top: 100px;
  padding-bottom: 100px;
  margin-bottom: 0;
  border-radius: 0 !important;
  background-size: cover;
  background: url(/wp-content/themes/pinnacleandco/dist/images/MetroValuations-Banner-Small1.jpg) no-repeat center;

  @media (min-width: $screen-sm-min) {
    padding-top: 200px;
    padding-bottom: 200px;
    background-size: cover;
    background: url(/wp-content/themes/pinnacleandco/dist/images/MetroValuations-Banner-Large1.jpg) no-repeat center;
  }

  @media (min-width: $screen-lg-min) {
    padding-top: 300px;
    padding-bottom: 300px;
    background-size: cover;
  }
}

.jumbotron.home {
  background-size: cover;
  background: url(/wp-content/themes/pinnacleandco/dist/images/MetroValuations-Banner-Small2.jpg) no-repeat center;

  @media (min-width: $screen-sm-min) {
    padding-top: 300px;
    padding-bottom: 300px;
    background-size: cover;
    background: url(/wp-content/themes/pinnacleandco/dist/images/MetroValuations-Banner-Large2.jpg) no-repeat center;
  }

  @media (min-width: $screen-lg-min) {
    padding-top: 300px;
    padding-bottom: 300px;
    background-size: cover;
  }
}

.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.row {
  margin: 0;
}

.row.services {

  .col-sm-6 {
    padding-left: 0;
    padding-right: 0;

    @media (min-width: $screen-sm-min) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

}

.contact-container {

  .col-sm-8, .col-sm-4 {
    padding-left: 0;
    padding-right: 0;

    @media (min-width: $screen-sm-min) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

}

.content-section {
  clear: both;
}

.services {
  margin-bottom: 50px;

  p {
    margin: 0 0 25px;
  }
}

.gform_wrapper .gform_footer input.button,
.gform_wrapper .gform_footer input[type=submit],
.gform_wrapper .gform_page_footer input.button,
.gform_wrapper .gform_page_footer input[type=submit] {
  background: $brand-red !important;
  padding: 10px 25px;
  color: $white;
  border: none;

  &:hover {
    background: darken($brand-red, 5%) !important;
  }
}

.gform_wrapper {
  margin: -15px 0 0 0 !important;
}

.contact {
  margin-bottom: 50px;
}

.lightweight-text {
  font-weight: 300;
}
h2 {
  color: #b80000;
}
h6 {
  color: grey;
  font-size: 16px;
  font-weight: 400;
}
.mt-0 {
  margin-top: 0;
}

.media-heading {
  margin-bottom: 15px;
}

.media-object {
  max-width: 100%;
  margin-bottom: 25px;

  @media (min-width: $screen-sm-min) {
    margin-bottom: 0;
  }
}

.media-left {
  padding-right: 25px;
}

.media-body, .media-left, .media-right {
  display: block;
  width: auto;

  @media (min-width: $screen-sm-min) {
    display: table-cell;
    width: 580px;
  }
}

.media-body ul li {
  font-size: 18px;
}

.list-item-dash::before {
  content: '— ';
  color: #ccc;
  margin-left: 0;

  @media (min-width: $screen-sm-min) {
    margin-left: -23px;
  }
}