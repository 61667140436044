.nav-flex {
  background: $brand-red;
}

.nav-flex .container-short {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1170px;
  margin: 0 auto;

  a {
    color: $white;
  }
}

.nav-flex-item.inner {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.nav-flex-item.inner-text {
  display: flex;
}

.nav-flex-item-logo {
  background: $white;
  padding: 20px;
  width: 100%;

  @media (min-width: $screen-sm-min) {
    width: 350px;
  }

  img {
    width: 100%;
  }
}

.nav-item {
  padding: 15px 15px;
  font-size: 18px;

  &:hover {
    background: darken($brand-red, 5%);
  }

  @media (min-width: $screen-sm-min) {
    display: block;
    padding: 40px 20px;
  }
}

.home .nav-item.homepage {
  background: darken($brand-red, 5%);
}

.contact .nav-item.contactpage {
  background: darken($brand-red, 5%);
}

.hidden-xs {
  display: none;

  @media (min-width: $screen-md-min) {
    display: block;
  }
}

.hidden-xs-up {
  display: block;

  @media (min-width: $screen-md-min) {
    display: none;
  }
}