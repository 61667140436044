// Glyphicons font path
$icon-font-path: "../fonts/";

// Grid settings
$main-sm-columns: 12;
$sidebar-sm-columns: 4;

// Colors
$brand-red: #cc0000;
$brand-grey: #939393;
//$brand-primary: $brand-grey;
$brand-success: $brand-red;
$brand-grey-dark: #3f3f3f;
$black: #000;
$white: #fff;

$font-family-sans-serif: "Raleway", Helvetica, Arial, sans-serif;
$font-family-serif: Georgia, "Times New Roman", Times, serif;

$font-family-monospace: Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family-base: $font-family-sans-serif;

$headings-font-family: $font-family-sans-serif;
$headings-font-weight: 700;
$headings-line-height: 1.1;
$headings-color: inherit;